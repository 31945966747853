import i18n from '../../../i18n';
import { ColumnDefinition } from '../../../../../../packages/data-grid/src/interfaces/ColumnDefinition';
import styles from './ImportPage.module.scss';
import { ItemSheetRequestIndexes, NOT_INCLUDED_INDEX } from './ImportSelectHeaders';

const buildStatusColumn = (value) => {
    const message = value.substring(value.indexOf(': ') + 1);
    if (value?.includes('WARNING')) {
        return `<span class="spot-pill spot-pill--warning ${styles.pill}">
                    <svg class="spot-icon spot-pill__icon spot-pill__icon--left" aria-labelledby="title">
                    <use xlink:href="/assets/spot/icons/spot_icons.svg#alert-notification"></use>
                    </svg>
                    <span data-automation-id="import-status-pill-text" class="spot-pill__icon-label"> ${message} </span>
                </span>`;
    } else if (value?.includes('ERROR')) {
        return `<span class="spot-pill spot-pill--negative ${styles.pill}"> 
                    <svg class="spot-icon spot-pill__icon spot-pill__icon--left" aria-labelledby="title">
                    <use xlink:href="/assets/spot/icons/spot_icons.svg#alert-notification"></use>
                    </svg>
                    <span data-automation-id="import-status-pill-text" class="spot-pill__icon-label"> ${message} </span>
                </span>`;
    } else if (value?.includes('SUCCESS')) {
        return `<span class="spot-pill spot-pill--positive ${styles.pill}"> 
                    <svg class="spot-icon spot-pill__icon spot-pill__icon--left" aria-labelledby="title">
                    <use xlink:href="/assets/spot/icons/spot_icons.svg#checkmark"></use>
                    </svg>
                    <span data-automation-id="import-status-pill-text" class="spot-pill__icon-label"> ${message} </span> 
                </span>`;
    } else {
        return `<span class="spot-pill spot-pill--warning ${styles.pill}"> 
                    <svg class="spot-icon spot-pill__icon spot-pill__icon--left" aria-labelledby="title">
                    <use xlink:href="/assets/spot/icons/spot_icons.svg#alert-notification"></use>
                    </svg>
                    <span data-automation-id="import-status-pill-text" class="spot-pill__icon-label"> Unknown Status </span> 
                </span>`;
    }
};

// The translation keys will be empty if sending the fallback text via options.
// As a quick work around we can use or operators until we have figured out how
// to use React.Suspense with i18next
export const columnDefinitions: ColumnDefinition[] = [
    {
        field: 'displayable.idexxSapId',
        headerName: i18n.t('controlCenter:import.column.idexxSapId', 'IDEXX SAP ID') || 'IDEXX SAP ID',
    },
    {
        field: 'displayable.practiceName',
        headerName: i18n.t('controlCenter:import.column.practiceName', 'Practice Name') || 'Practice Name',
    },
    {
        field: 'item.pimsId',
        headerName: i18n.t('controlCenter:import.column.itemId', 'Item ID') || 'Item ID',
    },
    {
        field: 'item.hospitalDescription',
        headerName: i18n.t('controlCenter:import.column.description', 'Description') || 'Description',
    },
    {
        field: 'displayable.currentSalesPrice',
        headerName: i18n.t('controlCenter:import.column.currentBasePrice', 'Current Base Price') || 'Current Base Price',
        cellClass: styles.originalPriceHeader,
        headerClass: styles.originalPriceCell,
        cellRenderer: (params) => {
            const { salesPrice } = params.data.configurable;

            if (salesPrice === undefined || salesPrice === null) {
                return undefined;
            }

            return params.value;
        },
    },
    {
        field: 'configurable.salesPrice',
        headerName: i18n.t('controlCenter:import.column.newBasePrice', 'New Base Price') || 'New Base Price',
    },
    {
        field: 'item.classification.description',
        headerName: i18n.t('controlCenter:import.column.classDescription', 'Class Description') || 'Class Description',
    },
    {
        field: 'item.subClassification.description',
        headerName: i18n.t('controlCenter:import.column.subclassDescription', 'Subclass Description') || 'Subclass Description',
    },
    {
        field: 'item.status.message',
        headerName: i18n.t('controlCenter:import.column.status', 'Status') || 'Status',
        resizable: true,
        cellStyle: (params) => {
            return {
                width: '350px',
                display: 'flex',
                paddingTop: '5px',
            };
        },
        cellRenderer: (params) => {
            return buildStatusColumn(params.value);
        },
    },
];

export const getAnimanaColumnDefinitions = (indexes: ItemSheetRequestIndexes): ColumnDefinition[] => {
    const columns: ColumnDefinition[] = [
        {
            field: 'displayable.idexxSapId',
            headerName: i18n.t('controlCenter:import.column.idexxSapId', 'IDEXX SAP ID') || 'IDEXX SAP ID',
        },
        {
            field: 'displayable.practiceName',
            headerName: i18n.t('controlCenter:import.column.practiceName', 'Practice Name') || 'Practice Name',
        },
    ];

    if (indexes.itemIdIndex) {
        columns.push({
            field: 'item.pimsId',
            headerName: i18n.t('controlCenter:import.column.itemId', 'Item ID') || 'Item ID',
        });
    }

    columns.push({
        field: 'item.hospitalDescription',
        headerName: i18n.t('controlCenter:import.column.description', 'Description') || 'Description',
    });

    if (indexes.itemSupplierCodeIndex) {
        columns.push({
            field: 'item.supplierCode',
            headerName: i18n.t('controlCenter:import.column.supplierCode', 'Supplier Code') || 'Supplier Code',
        });
    }

    if (!indexes.listPriceIndex || indexes.listPriceIndex !== NOT_INCLUDED_INDEX) {
        columns.push(
            {
                field: 'displayable.currentListPrice',
                headerName: i18n.t('controlCenter:import.column.currentListPrice', 'Current List Price') || 'Current List Price',
                cellClass: styles.originalPriceHeader,
                headerClass: styles.originalPriceCell,
                cellRenderer: (params) => {
                    const { listPrice } = params.data.configurable;

                    if (listPrice === undefined || listPrice === null) {
                        return undefined;
                    }

                    return params.value;
                },
            },
            {
                field: 'configurable.listPrice',
                headerName: i18n.t('controlCenter:import.column.newListPrice', 'New List Price') || 'New List Price',
            },
        );
    }

    if (!indexes.costPriceIndex || indexes.costPriceIndex !== NOT_INCLUDED_INDEX) {
        columns.push(
            {
                field: 'displayable.currentCostPrice',
                headerName: i18n.t('controlCenter:import.column.currentCostPrice', 'Current Cost Price') || 'Current Cost Price',
                cellClass: styles.originalPriceHeader,
                headerClass: styles.originalPriceCell,
                cellRenderer: (params) => {
                    const { costPrice } = params.data.configurable;

                    if (costPrice === undefined || costPrice === null) {
                        return undefined;
                    }

                    return params.value;
                },
            },
            {
                field: 'configurable.costPrice',
                headerName: i18n.t('controlCenter:import.column.newCostPrice', 'New Cost Price') || 'New Cost Price',
            },
        );
    }

    if (!indexes.salesPriceIndex || indexes.salesPriceIndex !== NOT_INCLUDED_INDEX) {
        columns.push(
            {
                field: 'displayable.currentSalesPrice',
                headerName: i18n.t('controlCenter:import.column.currentSalesPrice', 'Current Sales Price') || 'Current Sales Price',
                cellClass: styles.originalPriceHeader,
                headerClass: styles.originalPriceCell,
                cellRenderer: (params) => {
                    const { salesPrice } = params.data.configurable;

                    if (salesPrice === undefined || salesPrice === null) {
                        return undefined;
                    }

                    return params.value;
                },
            },
            {
                field: 'configurable.salesPrice',
                headerName: i18n.t('controlCenter:import.column.newSalesPrice', 'New Sales Price') || 'New Sales Price',
            },
        );
    }

    columns.push(
        {
            field: 'item.classification.description',
            headerName: i18n.t('controlCenter:import.column.classDescription', 'Class Description') || 'Class Description',
        },
        {
            field: 'item.status.message',
            headerName: i18n.t('controlCenter:import.column.status', 'Status'),
            resizable: true,
            minWidth: 350,
            cellRenderer: (params) => {
                return buildStatusColumn(params.value);
            },
        },
    );

    return columns;
};
