import React, { ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { ImportStartPage } from './ImportStartPage';
import { ProTips } from '../../../components/helpers/ProTips';
import { DataGrid, generateCsv } from '@enterprise/data-grid';
import { ImportSelectHeaders, ItemSheetRequestIndexes, NOT_INCLUDED_INDEX } from './ImportSelectHeaders';
import classNames from 'classnames';
import { RouterPaths } from '../../../router/RouterPaths';
import { columnDefinitions, getAnimanaColumnDefinitions } from './ColumnDefinitions';
import { Notifications } from '../../Notifications';
import i18n from 'i18next';
import { ScheduledItemSaveModal } from '../items/modals/ScheduledItemSaveModal';
import queryString from 'query-string';
import styles from './ImportPage.module.scss';
import { observer } from 'mobx-react';
import { ColumnDefinition } from '@enterprise/data-grid/src/interfaces/ColumnDefinition';
import FileSaver from 'file-saver';
import { SpotButton } from '@enterprise/spot';
import { FEATURES } from '@enterprise/core';
import { useStores } from '../../../hooks';
import { useLocation } from 'react-router-dom';
import { FeatureOrComingSoon } from '../../../components/helpers/FeatureOrComingSoon';
import { FlexPageWrapper } from '../../../layout/main/flexPageWrapper';

export enum ItemIndexType {
    DESCRIPTION = 'description',
    ID = 'id',
    SUPPLIER_CODE = 'supplierCode',
}

export enum ImportStatusType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    WARNING = 'WARNING',
}

export interface ItemSheetRequestFields {
    salesPrice: string;
    costPrice: string;
    listPrice: string;
    item: string;
    idexxSapId?: string;
    itemType: ItemIndexType;
}

export const ImportPage = observer((props: { header?: JSX.Element }) => {
    const {
        ui: { app, router },
        domain: { importPageStore: store, itemsPageStore: itemsStore, constantsStore },
    } = useStores();

    const { importRows, csvHeader, itemsGroupBy, isLoading: isStoreLoading, isInitialState } = store;
    const { isAnimanaOnly } = itemsStore;
    const { isLoading: isConstantsLoading } = constantsStore;

    const isLoading = isConstantsLoading || isStoreLoading;

    const hasErrors = useMemo(() => {
        return importRows.some((row) => row.item?.status?.type == ImportStatusType.ERROR);
    }, [importRows]);

    const [animanaColumnDefinitions, setAnimanaColumnDefinitions] = useState<ColumnDefinition[]>([]);
    const location = useLocation();

    const inputOpenFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // const { location } = this.props;
        const { dashboardId, dashboardFilters } = queryString.parse(location && location.search, { decode: true });
        const filters = JSON.parse(String(dashboardFilters || 'null'));
        if (dashboardId) {
            store.fetchDataGridFromDashboard(String(dashboardId), filters);
        }

        return () => {
            store.dispose();
        };
    }, []);

    const clearSelectedFile = (): void => {
        if (inputOpenFileRef.current) {
            inputOpenFileRef.current.value = '';
        }
    };

    const isEnhancedSearch = app.featureManager.isAvailable(FEATURES.ENHANCED_PRODUCT_SEARCH);

    const fetchImportData = ({
        salesPriceIndexNumber,
        idexxSapIdIndexNumber,
        itemIndexNumber,
        listPriceIndexNumber,
        costPriceIndexNumber,
        itemType,
    }): void => {
        const formatted = store.csvRecords.map((record) => {
            const salesPrice: string = record[salesPriceIndexNumber]?.trim() || '';
            const listPrice: string = record[listPriceIndexNumber]?.trim() || '';
            const costPrice: string = record[costPriceIndexNumber]?.trim() || '';
            const item: string = record[itemIndexNumber].trim();
            const idexxSapId: string = record[idexxSapIdIndexNumber].trim();
            const indexItemType: ItemIndexType = itemType;

            if (!salesPrice && !listPrice && !costPrice) {
                throw new Error('Base price and item id should contain values');
            }
            const formattedCSVRow: ItemSheetRequestFields = {
                salesPrice,
                listPrice,
                costPrice,
                idexxSapId,
                item,
                itemType: indexItemType,
            };
            if (idexxSapId === '') {
                formattedCSVRow.idexxSapId = undefined;
            }
            return formattedCSVRow;
        });

        store.fetchImportData(formatted);
    };

    const fetchItemsAndSendFile = (indexes: ItemSheetRequestIndexes): void => {
        const { costPriceIndex, listPriceIndex, salesPriceIndex, idexxSapIdIndex, itemIdIndex, itemDescriptionIndex, itemSupplierCodeIndex } =
            indexes;
        setAnimanaColumnDefinitions(getAnimanaColumnDefinitions(indexes));
        store.sendImportData({
            costPriceIndex,
            listPriceIndex,
            salesPriceIndex,
            idexxSapIdIndex,
            itemIdIndex,
            itemDescriptionIndex,
            itemSupplierCodeIndex,
        });
    };

    const fetchItems = (indexes: ItemSheetRequestIndexes): void => {
        const { costPriceIndex, listPriceIndex, salesPriceIndex, idexxSapIdIndex, itemIdIndex, itemDescriptionIndex, itemSupplierCodeIndex } =
            indexes;
        const salesPriceIndexNumber = salesPriceIndex != NOT_INCLUDED_INDEX ? Number(salesPriceIndex) : undefined;
        const listPriceIndexNumber = listPriceIndex != NOT_INCLUDED_INDEX ? Number(listPriceIndex) : undefined;
        const costPriceIndexNumber = costPriceIndex != NOT_INCLUDED_INDEX ? Number(costPriceIndex) : undefined;
        const idexxSapIdIndexNumber = Number(idexxSapIdIndex);

        const excludeFields: string[] = [];

        if (!salesPriceIndexNumber) {
            excludeFields.push('displayable.currentSalesPrice', 'configurable.salesPrice');
        }

        if (!listPriceIndexNumber) {
            excludeFields.push('displayable.currentListPrice', 'configurable.listPrice');
        }

        if (!costPriceIndexNumber) {
            excludeFields.push('displayable.currentCostPrice', 'configurable.costPrice');
        }

        setAnimanaColumnDefinitions(getAnimanaColumnDefinitions(indexes).filter(({ field }: ColumnDefinition) => !excludeFields.includes(field)));

        try {
            if (itemDescriptionIndex === undefined && itemSupplierCodeIndex === undefined) {
                fetchImportData({
                    salesPriceIndexNumber,
                    idexxSapIdIndexNumber,
                    itemIndexNumber: Number(itemIdIndex),
                    listPriceIndexNumber,
                    costPriceIndexNumber,
                    itemType: ItemIndexType.ID,
                });
            } else if (itemSupplierCodeIndex) {
                fetchImportData({
                    salesPriceIndexNumber,
                    idexxSapIdIndexNumber,
                    itemIndexNumber: Number(itemSupplierCodeIndex),
                    listPriceIndexNumber,
                    costPriceIndexNumber,
                    itemType: ItemIndexType.SUPPLIER_CODE,
                });
            } else {
                fetchImportData({
                    salesPriceIndexNumber,
                    idexxSapIdIndexNumber,
                    itemIndexNumber: Number(itemDescriptionIndex),
                    listPriceIndexNumber,
                    costPriceIndexNumber,
                    itemType: ItemIndexType.DESCRIPTION,
                });
            }
        } catch (err) {
            store.csvHeader = [];
            Notifications.error(i18n.t('controlCenter:import.errorParsingCSV', `Error parsing CSV, please check your formatting and try again`));
            clearSelectedFile();
        }
    };

    const downloadCsv = () => {
        if (isEnhancedSearch) {
            store.downloadImportMapping();
        } else {
            const data = generateCsv();
            if (data) {
                const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
                FileSaver.saveAs(csvData, `import-${new Date().toISOString().split('T')[0]}.csv`);
            }
        }
    };

    const getColumnContent = (): ReactElement => {
        if (importRows.length) {
            return (
                <div className={styles.container}>
                    <Row className={styles.actionBar}>
                        <Col xs={12}>
                            {isEnhancedSearch && (
                                <div className={styles.totalItems}>
                                    {i18n.t('controlCenter:import.showingTotalItems', {
                                        totalItems: store.importRows.length,
                                        visibleItems: store.totalItems,
                                        defaultValue: `Showing ${store.importRows.length} of ${store.totalItems}`,
                                    })}
                                </div>
                            )}
                            <span className={styles.csvInfo}>
                                {i18n.t('controlCenter:import.getFullyPreparedImport', 'To get the fully prepared import, click Download CSV')}
                            </span>
                            <SpotButton
                                className={styles.exportCsvButton}
                                isPrimary={true}
                                onClick={downloadCsv}
                                data-automation-id="import-download-csv-button"
                            >
                                {i18n.t('controlCenter:import.downloadCsv', 'Download CSV')}
                            </SpotButton>
                            <ScheduledItemSaveModal
                                disabled={hasErrors}
                                onSave={scheduleBulkUpdate}
                                title={i18n.t('controlCenter:import.scheduleBulkItemUpdate', 'Schedule bulk items update')}
                            />
                        </Col>
                    </Row>

                    {!isAnimanaOnly && <DataGrid rowData={importRows || []} columnDefinitions={columnDefinitions} />}
                    {isAnimanaOnly && <DataGrid rowData={importRows || []} columnDefinitions={animanaColumnDefinitions} />}
                </div>
            );
        }

        if (csvHeader.length) {
            return (
                <ImportSelectHeaders
                    columnHeaders={csvHeader}
                    itemsGroupBy={itemsGroupBy}
                    isAnimana={isAnimanaOnly}
                    submitHandler={isEnhancedSearch ? fetchItemsAndSendFile : fetchItems}
                />
            );
        }

        return <ImportStartPage uploadHandler={uploadHandler} />;
    };

    const getPageContents = (): ReactElement => {
        if (isLoading) {
            return <ProTips className={styles.proTips} />;
        }
        return (
            <>
                <input accept={'.csv'} onChange={onFileChange} ref={inputOpenFileRef} type={'file'} className={'display-none'} />
                <Row>
                    <Col xs={12}>{getColumnContent()}</Col>
                </Row>
            </>
        );
    };

    const onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const file = event.target?.files?.item(0);
        if (!file) {
            return;
        }
        isEnhancedSearch ? store.parseInputHeaderOnly(file, clearSelectedFile) : store.parseInputFile(file, clearSelectedFile);
    };

    const scheduleBulkUpdate = (data): void => {
        if (isEnhancedSearch) {
            store.createBulkUpdateFromFile(data).then(() => {
                router.push(RouterPaths.ControlCenterPages.Activity);
            });
        } else {
            store.createBulkUpdate(data).then(() => {
                router.push(RouterPaths.ControlCenterPages.Activity);
            });
        }
    };

    const uploadHandler = (): void => {
        inputOpenFileRef.current?.click();
    };

    const clearPageState = (): void => {
        clearSelectedFile();
        store.dispose();
    };

    return (
        <FlexPageWrapper className={styles.wrapper} back={!isInitialState ? () => clearPageState() : undefined} header={props.header}>
            <FeatureOrComingSoon name={FEATURES.CONTROL_CENTER}>
                <>
                    <div className={styles.titleBlock}>
                        <div>
                            <div className="spot-typography__heading--level-3" data-automation-id="organization-practices-heading">
                                {i18n.t('controlCenter:title', 'Control Center')}
                            </div>
                            <div className="spot-typography__text--tertiary">
                                {i18n.t('controlCenter:description', 'Centrally manage individual practice applications')}
                            </div>
                        </div>
                    </div>
                    <Container className={classNames({ [styles.gridDisplayed]: importRows.length })}>{getPageContents()}</Container>
                </>
            </FeatureOrComingSoon>
        </FlexPageWrapper>
    );
});
